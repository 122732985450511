import { getIsOlympics, getTileState, setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT, PROGRAMMING_TYPES, OLYMPICS_FALLBACK_VALUE, } from '../../../../constants';
export const getSlideType = (analytics) => (getIsOlympics(analytics) && analytics.contentType) ||
    analytics.programmingType ||
    analytics.programType ||
    (analytics.isLive && 'Live') ||
    (analytics.movie && 'Movie') ||
    (analytics.series && 'Show') ||
    MPARTICLE_DEFAULT_ATTR_VALUE;
const getSlideEntitlement = (analytics) => {
    if ((analytics === null || analytics === void 0 ? void 0 : analytics.destinationType) === 'video') {
        return analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE;
    }
    return (analytics === null || analytics === void 0 ? void 0 : analytics.liveEntitlement) || MPARTICLE_DEFAULT_ATTR_VALUE;
};
export const isSLE = (programType) => {
    return programType === PROGRAMMING_TYPES.SLE;
};
const getSlideAttributes = (analytics = {}, shelf = {}, tileParams = {}) => {
    var _a;
    const isSmartDynamicLead = !!analytics.smartDynamicCTA;
    const isOlympics = getIsOlympics(analytics);
    return setDefaultValue({
        'Item Clicked Name': analytics.videoTitle,
        'Item Clicked Type': getSlideType(analytics),
        'Item Clicked Show': isOlympics ? OLYMPICS_FALLBACK_VALUE : analytics.series,
        'Item Clicked Season': analytics.seasonNumber,
        'Item Clicked Video ID': analytics.mpxGuid || analytics.pid,
        'Item Clicked Entitlement': getSlideEntitlement(analytics),
        'Item Clicked Brand': (_a = analytics.brand) === null || _a === void 0 ? void 0 : _a.title,
        'Custom Shelf Title': shelf.listTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Smart Tile Title': analytics.smartTileLabel,
        'Smart Tile Scenario': analytics.smartTileScenario,
        'Smart Tile Episode Title': isSmartDynamicLead && analytics.videoTitle,
        'Smart Tile Video ID': isSmartDynamicLead && analytics.mpxGuid,
        'Smart Tile Logic': analytics.smartTileLogic,
        'Custom Shelf Type': analytics.shelfType || 'Regular',
        Sponsor: analytics.sponsorName,
        'Item Clicked Playlist Name': analytics.isPlaylist && analytics.playlistMachineName,
        'Item Clicked Sport': analytics === null || analytics === void 0 ? void 0 : analytics.sport,
        'Item Clicked League': isOlympics ? OLYMPICS_FALLBACK_VALUE : analytics === null || analytics === void 0 ? void 0 : analytics.league,
        'Item Clicked State': getTileState(tileParams),
        'Shelf Machine Name': shelf.machineName,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getSlideAttributes;
