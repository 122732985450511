import { createItems } from '../../components/componentMaps';
import Container from '../models/Container';
import { useRequest } from '../../lib/useRequest';
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request';
export default class ContinuousScroll {
    constructor(continuousScroll, urlAlias) {
        this._continuousScroll = continuousScroll;
        this._urlAlias = urlAlias;
    }
    async getMoreItems(queryVariables) {
        const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch();
        const { data = {} } = new Container(moreItemsData === null || moreItemsData === void 0 ? void 0 : moreItemsData.data);
        return {
            items: createItems(data === null || data === void 0 ? void 0 : data.items),
            next: data === null || data === void 0 ? void 0 : data.next,
            previous: data === null || data === void 0 ? void 0 : data.previous,
            groupIds: data === null || data === void 0 ? void 0 : data.groupIds,
        };
    }
    get initiallySelected() {
        return this._continuousScroll.data.initiallySelected || 0;
    }
    get initialItemHandle() {
        return this._continuousScroll.data.initialItemHandle;
    }
}
