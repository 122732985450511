import { useRequest } from '../lib/useRequest';
import PlayerStoreSingleton from '../store/PlayerStore/PlayerStore';
import { setEpgData } from '../store/PlayerStore/actions/epg';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
import { LIVE_PLAYER_TAG, ROUTE } from '../constants';
import { Router } from '@lightningjs/sdk';
import { LiveStreamManager } from '../lib/LiveStreamManager';
import { getLiveCallSignByStreamAccessName } from './variables';
import { findLinkSelectableComponent, setStreamData } from '../store/PlayerStore/actions';
import { LiveScheduleRequestConfig, OlympicsStreamRequestConfig, SlePageRequestConfig, StreamRequestConfig, VideoPageRequestConfig, } from './request';
import { EpgGuideController } from '../components/EpgGuideV2/EpgGuideController';
export const loadData = async (page) => await page.load();
export const getBffDataFromPid = async (pid) => {
    return await useRequest(SlePageRequestConfig(pid)).fetch();
};
export const getBffDataForLive = async () => {
    const data = await useRequest(LiveScheduleRequestConfig()).fetch();
    if (!data)
        throw data;
    await PlayerStoreSingleton.dispatch(setEpgData(data));
};
export const getStreamData = async () => {
    const streamAccessName = LiveStreamManager.get().accessName;
    const callSign = getLiveCallSignByStreamAccessName(streamAccessName);
    try {
        const data = await useRequest(StreamRequestConfig(callSign, LiveStreamManager.isGlobalNavigation)).fetch();
        if (!data)
            throw data;
        await PlayerStoreSingleton.dispatch(setStreamData(data));
        EpgGuideController.setTabs(findLinkSelectableComponent(data.sections));
    }
    catch (_error) {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            code: LIVE_PLAYER_TAG,
            description: 'unable to load stream data',
            payload: callSign,
        });
        Router.navigate(ROUTE.error);
    }
    finally {
        LiveStreamManager.setIsGlobalNavigation(false);
    }
};
export const getVodStreamRequestConfig = (isOlympics = false, videoId) => {
    const requestConfig = isOlympics ? OlympicsStreamRequestConfig : VideoPageRequestConfig;
    return requestConfig(videoId);
};
