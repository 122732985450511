export const MPARTICLE_DEFAULT_ATTR_VALUE = 'None';
export var TOKEN_TYPE;
(function (TOKEN_TYPE) {
    TOKEN_TYPE["AUTH_KILL"] = "Auth Kill";
    TOKEN_TYPE["TEMP_PASS"] = "TempPass";
    TOKEN_TYPE["MVPD"] = "MVPD";
    TOKEN_TYPE["FREE_CONTENT_GATING"] = "Free Content Gating";
})(TOKEN_TYPE || (TOKEN_TYPE = {}));
export var MPARTICLE_CLICK_STATES;
(function (MPARTICLE_CLICK_STATES) {
    MPARTICLE_CLICK_STATES["LIVE"] = "Live";
    MPARTICLE_CLICK_STATES["UPCOMING"] = "Upcoming";
    MPARTICLE_CLICK_STATES["FER"] = "Full Event Replay";
})(MPARTICLE_CLICK_STATES || (MPARTICLE_CLICK_STATES = {}));
export const CONVIVA_DEFAULT_ATTR_VALUE = 'N/A';
export const ADOBE_DEFAULT_ATTR_VALUE = 'unknown';
export var MPARTICLE_ERROR_CONTEXTS;
(function (MPARTICLE_ERROR_CONTEXTS) {
    MPARTICLE_ERROR_CONTEXTS["vod"] = "vod";
    MPARTICLE_ERROR_CONTEXTS["live"] = "live";
})(MPARTICLE_ERROR_CONTEXTS || (MPARTICLE_ERROR_CONTEXTS = {}));
export var PAGE_NAME;
(function (PAGE_NAME) {
    PAGE_NAME["main"] = "Homepage";
    PAGE_NAME["shows"] = "All Shows Page : All";
    PAGE_NAME["showHome"] = "Show Home Page";
    PAGE_NAME["movieHome"] = "Movie Home Page";
    PAGE_NAME["videoPlayer"] = "Video Player";
    PAGE_NAME["videoEndCard"] = "Video End Card";
    PAGE_NAME["settings"] = "Settings";
    PAGE_NAME["about"] = "About";
    PAGE_NAME["closedCaptions"] = "Closed Captions";
    PAGE_NAME["contactUs"] = "Contact Us";
    PAGE_NAME["faq"] = "FAQ";
    PAGE_NAME["language"] = "Language";
    PAGE_NAME["myProfile"] = "My Profile";
    PAGE_NAME["activation"] = "Activation";
    PAGE_NAME["error"] = "Error";
    PAGE_NAME["creditConfirmation"] = "Watch Now With Credits";
    PAGE_NAME["search"] = "Search";
    PAGE_NAME["linearVideoPlayer"] = "Linear Video Player";
    PAGE_NAME["allBrands"] = "All Brands Page";
    PAGE_NAME["brandLanding"] = "Brand Landing Page";
    PAGE_NAME["liveAndUpcoming"] = "LIVE & Upcoming Page";
    PAGE_NAME["sports"] = "All Sports Page";
    PAGE_NAME["replays"] = "Replays";
    PAGE_NAME["olympics"] = "Olympics Hub";
    PAGE_NAME["olympicsReplays"] = "Olympics Replays Page";
    PAGE_NAME["olympicsHighlights"] = "Olympics Highlights Page";
    PAGE_NAME["softContentGating"] = "Free Content Soft Gating Modal";
    PAGE_NAME["hardContentGating"] = "Free Content Hard Gating Modal";
    PAGE_NAME["showExcludedFromCredits"] = "Show Excluded from Credits";
    PAGE_NAME["waysToWatch"] = "Ways to Watch";
})(PAGE_NAME || (PAGE_NAME = {}));
export var PAGE_TYPE;
(function (PAGE_TYPE) {
    PAGE_TYPE["main"] = "Homepage";
    PAGE_TYPE["shows"] = "Shows";
    PAGE_TYPE["showHome"] = "Show Home Page";
    PAGE_TYPE["movieHome"] = "Movie Home Page";
    PAGE_TYPE["videoPlayer"] = "Video Player";
    PAGE_TYPE["settings"] = "Settings";
    PAGE_TYPE["authFunnel"] = "Auth Funnel";
    PAGE_TYPE["error"] = "Error";
    PAGE_TYPE["search"] = "Search";
    PAGE_TYPE["linearVideoPlayer"] = "Linear Video Player";
    PAGE_TYPE["brands"] = "Brands";
    PAGE_TYPE["brandLanding"] = "Brand Landing Page";
    PAGE_TYPE["viewMore"] = "View More";
    PAGE_TYPE["live"] = "Live";
    PAGE_TYPE["sports"] = "Sports";
    PAGE_TYPE["olympics"] = "Olympics Hub";
})(PAGE_TYPE || (PAGE_TYPE = {}));
export var SIGN_IN_TYPE;
(function (SIGN_IN_TYPE) {
    SIGN_IN_TYPE["google"] = "Google";
    SIGN_IN_TYPE["facebook"] = "Facebook";
    SIGN_IN_TYPE["apple"] = "Apple";
    SIGN_IN_TYPE["email"] = "Email";
})(SIGN_IN_TYPE || (SIGN_IN_TYPE = {}));
export var CUSTOM_SHELF_TYPE;
(function (CUSTOM_SHELF_TYPE) {
    CUSTOM_SHELF_TYPE["regular"] = "Regular";
    CUSTOM_SHELF_TYPE["premium"] = "Premium";
})(CUSTOM_SHELF_TYPE || (CUSTOM_SHELF_TYPE = {}));
export var CUSTOM_LEAGUE;
(function (CUSTOM_LEAGUE) {
    CUSTOM_LEAGUE["olympics"] = "Paris 2024 Olympics";
})(CUSTOM_LEAGUE || (CUSTOM_LEAGUE = {}));
export const OLYMPICS_FALLBACK_VALUE = 'Olympics';
