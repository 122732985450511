export var COLORS;
(function (COLORS) {
    COLORS[COLORS["black"] = 4278190080] = "black";
    COLORS[COLORS["black1"] = 1291845632] = "black1";
    COLORS[COLORS["black2"] = 3640655872] = "black2";
    COLORS[COLORS["black3"] = 1073741824] = "black3";
    COLORS[COLORS["black4"] = 2147483648] = "black4";
    COLORS[COLORS["black5"] = 3422552064] = "black5";
    COLORS[COLORS["black6"] = 2785017856] = "black6";
    COLORS[COLORS["black7"] = 3003121664] = "black7";
    COLORS[COLORS["black8"] = 2852126720] = "black8";
    COLORS[COLORS["black9"] = 855638016] = "black9";
    COLORS[COLORS["black10"] = 1711276032] = "black10";
    COLORS[COLORS["cyan"] = 4278255615] = "cyan";
    COLORS[COLORS["onyx"] = 4061402132] = "onyx";
    COLORS[COLORS["white"] = 4294967295] = "white";
    COLORS[COLORS["white1"] = 4294901502] = "white1";
    COLORS[COLORS["white2"] = 4294769916] = "white2";
    COLORS[COLORS["white3"] = 4294046193] = "white3";
    COLORS[COLORS["white4"] = 3741319167] = "white4";
    COLORS[COLORS["white5"] = 4293388263] = "white5";
    COLORS[COLORS["white6"] = 4292730591] = "white6";
    COLORS[COLORS["white7"] = 520093695] = "white7";
    COLORS[COLORS["dark"] = 4280230183] = "dark";
    COLORS[COLORS["dark2"] = 4280427042] = "dark2";
    COLORS[COLORS["dark3"] = 452984831] = "dark3";
    COLORS[COLORS["dark4"] = 2569743147] = "dark4";
    COLORS[COLORS["dark5"] = 318767103] = "dark5";
    COLORS[COLORS["dark6"] = 1308622847] = "dark6";
    COLORS[COLORS["dark7"] = 4281677629] = "dark7";
    COLORS[COLORS["dark8"] = 2158208425] = "dark8";
    COLORS[COLORS["dark9"] = 872415231] = "dark9";
    COLORS[COLORS["dark10"] = 4285233554] = "dark10";
    COLORS[COLORS["dark11"] = 4285098345] = "dark11";
    COLORS[COLORS["lightGray"] = 3439329279] = "lightGray";
    COLORS[COLORS["lightGray1"] = 4293914607] = "lightGray1";
    COLORS[COLORS["lightGray2"] = 3221225471] = "lightGray2";
    COLORS[COLORS["lightGray3"] = 4294440951] = "lightGray3";
    COLORS[COLORS["lightGray4"] = 2583691263] = "lightGray4";
    COLORS[COLORS["lightGray5"] = 4288124823] = "lightGray5";
    COLORS[COLORS["lightGray6"] = 3741319167] = "lightGray6";
    COLORS[COLORS["lightGray7"] = 4284638055] = "lightGray7";
    COLORS[COLORS["lightGray8"] = 3019372535] = "lightGray8";
    COLORS[COLORS["lightGray9"] = 3438210798] = "lightGray9";
    COLORS[COLORS["lightGray10"] = 4285233554] = "lightGray10";
    COLORS[COLORS["lightGray11"] = 4292006868] = "lightGray11";
    COLORS[COLORS["lightGray12"] = 4288781725] = "lightGray12";
    COLORS[COLORS["lightGray13"] = 4294309365] = "lightGray13";
    COLORS[COLORS["lightGray14"] = 4293059298] = "lightGray14";
    COLORS[COLORS["magenta"] = 4294902015] = "magenta";
    COLORS[COLORS["mediumGray"] = 4286611584] = "mediumGray";
    COLORS[COLORS["mediumGray1"] = 3019898879] = "mediumGray1";
    COLORS[COLORS["mediumGray2"] = 4284506727] = "mediumGray2";
    COLORS[COLORS["mediumGray3"] = 2164260863] = "mediumGray3";
    COLORS[COLORS["mediumGray4"] = 1895825407] = "mediumGray4";
    COLORS[COLORS["mediumGray5"] = 1358954495] = "mediumGray5";
    COLORS[COLORS["mediumGray6"] = 4283190610] = "mediumGray6";
    COLORS[COLORS["warmGray"] = 4287401100] = "warmGray";
    COLORS[COLORS["warmGray1"] = 4292269782] = "warmGray1";
    COLORS[COLORS["warmGray2"] = 3003121663] = "warmGray2";
    COLORS[COLORS["warmGray3"] = 4293190884] = "warmGray3";
    COLORS[COLORS["warmGray4"] = 4289440683] = "warmGray4";
    COLORS[COLORS["charcoalGrey"] = 1090519039] = "charcoalGrey";
    COLORS[COLORS["thunder"] = 818728140] = "thunder";
    COLORS[COLORS["thunder1"] = 4280822320] = "thunder1";
    COLORS[COLORS["thunder2"] = 4280427822] = "thunder2";
    COLORS[COLORS["thunder3"] = 4281149485] = "thunder3";
    COLORS[COLORS["brightGrey"] = 4282468694] = "brightGrey";
    COLORS[COLORS["blue"] = 4278235883] = "blue";
    COLORS[COLORS["blue1"] = 4278190335] = "blue1";
    COLORS[COLORS["blue2"] = 4282415312] = "blue2";
    COLORS[COLORS["red"] = 4292158987] = "red";
    COLORS[COLORS["red1"] = 4294901760] = "red1";
    COLORS[COLORS["red2"] = 4293733389] = "red2";
    COLORS[COLORS["red3"] = 4287705379] = "red3";
    COLORS[COLORS["yellow"] = 4294967040] = "yellow";
    COLORS[COLORS["yellow2"] = 4290027636] = "yellow2";
    COLORS[COLORS["yellow3"] = 4294757394] = "yellow3";
    COLORS[COLORS["yellow4"] = 4294944768] = "yellow4";
    COLORS[COLORS["yellow5"] = 4294959104] = "yellow5";
    COLORS[COLORS["purple"] = 4286578816] = "purple";
    COLORS[COLORS["green"] = 4278222848] = "green";
    COLORS[COLORS["teal"] = 4278222976] = "teal";
    COLORS[COLORS["transparent"] = 0] = "transparent";
    COLORS[COLORS["menuLightGrey"] = 2516582400] = "menuLightGrey";
    COLORS[COLORS["menuLightGrey1"] = 754974720] = "menuLightGrey1";
    COLORS[COLORS["menuLightGrey2"] = 150994944] = "menuLightGrey2";
    COLORS[COLORS["nbc"] = 4283575953] = "nbc";
    COLORS[COLORS["liveEdgeProgress"] = 4285797638] = "liveEdgeProgress";
})(COLORS || (COLORS = {}));
export var FONT_FACE;
(function (FONT_FACE) {
    FONT_FACE["light"] = "Light";
    FONT_FACE["regular"] = "Regular";
    FONT_FACE["semiBold"] = "SemiBold";
    FONT_FACE["bold"] = "Bold";
    FONT_FACE["roboto"] = "Roboto";
    FONT_FACE["coronet"] = "Coronet";
    FONT_FACE["courier"] = "Courier";
    FONT_FACE["impress"] = "Impress";
    FONT_FACE["timesNewRoman"] = "TimesNewRoman";
    FONT_FACE["helvetica"] = "Helvetica";
    FONT_FACE["arial"] = "Arial";
    FONT_FACE["copperplate"] = "Copperplate";
})(FONT_FACE || (FONT_FACE = {}));
export var FLEX_DIRECTION;
(function (FLEX_DIRECTION) {
    FLEX_DIRECTION["row"] = "row";
    FLEX_DIRECTION["column"] = "column";
})(FLEX_DIRECTION || (FLEX_DIRECTION = {}));
export var JUSTIFY_CONTENT;
(function (JUSTIFY_CONTENT) {
    JUSTIFY_CONTENT["center"] = "center";
    JUSTIFY_CONTENT["flexEnd"] = "flex-end";
    JUSTIFY_CONTENT["flexStart"] = "flex-start";
    JUSTIFY_CONTENT["spaceBetween"] = "space-between";
    JUSTIFY_CONTENT["spaceAround"] = "space-around";
    JUSTIFY_CONTENT["spaceEvenly"] = "space-evenly";
})(JUSTIFY_CONTENT || (JUSTIFY_CONTENT = {}));
export var ALIGN_CONTENT;
(function (ALIGN_CONTENT) {
    ALIGN_CONTENT["center"] = "center";
    ALIGN_CONTENT["flexEnd"] = "flex-end";
})(ALIGN_CONTENT || (ALIGN_CONTENT = {}));
export var ALIGN_ITEMS;
(function (ALIGN_ITEMS) {
    ALIGN_ITEMS["center"] = "center";
    ALIGN_ITEMS["flexStart"] = " flex-start";
    ALIGN_ITEMS["flexEnd"] = "flex-end";
    ALIGN_ITEMS["stretch"] = "stretch";
    ALIGN_ITEMS["baseline"] = "baseline";
})(ALIGN_ITEMS || (ALIGN_ITEMS = {}));
export var VERTICAL_ALIGN;
(function (VERTICAL_ALIGN) {
    VERTICAL_ALIGN["middle"] = "middle";
    VERTICAL_ALIGN["top"] = "top";
    VERTICAL_ALIGN["bottom"] = "bottom";
})(VERTICAL_ALIGN || (VERTICAL_ALIGN = {}));
export var TEXT_ALIGN;
(function (TEXT_ALIGN) {
    TEXT_ALIGN["center"] = "center";
})(TEXT_ALIGN || (TEXT_ALIGN = {}));
export var ALIGN_SELF;
(function (ALIGN_SELF) {
    ALIGN_SELF["center"] = "center";
    ALIGN_SELF["flexEnd"] = "flex-end";
    ALIGN_SELF["flexStart"] = "flex-start";
    ALIGN_SELF["stretch"] = "stretch";
})(ALIGN_SELF || (ALIGN_SELF = {}));
export const MAX_LINES_SUFFIX = '...';
export const BADGE_LABELS = ['NEW', 'PREMIERE', 'EXPIRING'];
export const END_CARD_AUTOPLAY_TIME = 5;
export const END_CARD_SLE_AUTOPLAY_TIME = 15;
export const END_CARD_FER_AUTOPLAY_TIME = 15;
export const END_CARD_FER_TRIGGER_TIME = 35;
export const BASE_FPS = 54;
export var SCREEN_SIZE;
(function (SCREEN_SIZE) {
    SCREEN_SIZE[SCREEN_SIZE["width"] = 1920] = "width";
    SCREEN_SIZE[SCREEN_SIZE["height"] = 1080] = "height";
})(SCREEN_SIZE || (SCREEN_SIZE = {}));
export const EPG_SLOT_WIDTH = 558;
export const EPG_SLOT_PADDING = 4;
export const WORD_WRAP_WIDTH = 510;
export var PLAYER_SIZE;
(function (PLAYER_SIZE) {
    PLAYER_SIZE["FULL"] = "FULL";
    PLAYER_SIZE["SECONDARY"] = "SECONDARY";
})(PLAYER_SIZE || (PLAYER_SIZE = {}));
export const FULL_SCHEDULE = ['full schedule', 'calendario completo'];
export const DEFAULT_STAGE_STYLES = `
          @media all {
            html {
              height: 100%; width: 100%;
            } 
            *,body {
              margin:0; 
              padding:0;
            } 
            canvas { 
              position: 
              absolute; 
              z-index: 2; 
            } body { 
              width: 100%; 
              height: 100%; 
              background-color: #000000; 
              overflow: hidden;
            }
          }`;
