import Collection from './Collection';
import componentTypes from '../../types/componentTypes';
import Tiles from '../tiles';
import { Container } from '../index';
export default class BrandSelectableGroup extends Collection {
    constructor(obj = {}) {
        super(obj);
        this.obj.brandItemLabels = this.mapBrandItemLabels(this.obj.brandItemLabels);
    }
    mapBrandItemLabels(items) {
        return (items
            .filter((item) => 
        // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
        item != null && Object.values(componentTypes).indexOf(item.component) > -1)
            // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
            .map((item) => new Tiles[item.component](item)));
    }
    mapItems(items) {
        return items
            .filter((item) => item != null && Object.values(componentTypes).indexOf(item.component) > -1)
            .map((item) => new Container(item));
    }
    get brandItemLabels() {
        return this.obj.brandItemLabels;
    }
}
