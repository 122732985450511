import { collectionComponents, createItems } from '../../components/componentMaps';
import { lowercaseFirst } from '../../helpers';
import { scrollFromSeason } from '../requests';
import Container from '../models/Container';
import Variables from '../../graphql/Variables';
import { ContinuousScrollSpawner } from './index';
import { useRequest } from '../../lib/useRequest';
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request';
export default class GroupedContinuousScroll {
    constructor(groupContinuousScroll, urlAlias) {
        this._groupContinuousScroll = groupContinuousScroll;
        this._urlAlias = urlAlias;
    }
    createItemLabels(fontSize = null) {
        const activeIndex = this.initiallySelected;
        return this._groupContinuousScroll.data.groups.map((item, index) => {
            var _a;
            return {
                label: item.title,
                active: index === activeIndex,
                fontSize,
                ariaLabel: (_a = this._groupContinuousScroll.data.itemLabels) === null || _a === void 0 ? void 0 : _a[index],
            };
        });
    }
    async createData(stage, index, initialItemHandle) {
        var _a, _b;
        let dataComponent = this._groupContinuousScroll.data.itemsComponentType;
        let dataItem = this._groupContinuousScroll.data;
        if (!index && this.initiallySelected) {
            index = this.initiallySelected;
        }
        const groupItem = dataItem.groups[index];
        if (groupItem && groupItem.id) {
            const queryData = await scrollFromSeason(Variables.getScrollFromSeasonVariables(groupItem.id, this._urlAlias));
            if (queryData && queryData.data) {
                const containerData = new Container(queryData.data, index);
                this._groupContinuousScroll = containerData;
                dataComponent = (_a = containerData.data) === null || _a === void 0 ? void 0 : _a.itemsComponentType;
                dataItem = (_b = containerData.data) === null || _b === void 0 ? void 0 : _b.continuousScroll;
            }
            else {
                return;
            }
        }
        if (dataItem && dataItem.items) {
            dataItem.activeIndex = index;
            return stage.c({
                type: collectionComponents.get(dataComponent),
                tag: `${lowercaseFirst(dataItem.items[0].component)}`,
                items: createItems(dataItem.items),
                groupIds: this._groupContinuousScroll.data.continuousScroll.groupIds,
                initialItemHandle,
                initialItem: dataItem.initialItem,
                title: '',
                next: this._groupContinuousScroll.data.continuousScroll.next,
                previous: this._groupContinuousScroll.data.continuousScroll.previous,
                spawner: new ContinuousScrollSpawner(this._groupContinuousScroll.data.continuousScroll, stage.urlAlias),
            });
        }
    }
    async getMoreItems(queryVariables) {
        const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch();
        const { data = {} } = new Container(moreItemsData === null || moreItemsData === void 0 ? void 0 : moreItemsData.data);
        return {
            items: createItems(data === null || data === void 0 ? void 0 : data.items),
            moreItems: data === null || data === void 0 ? void 0 : data.moreItems,
        };
    }
    get initiallySelected() {
        return this._groupContinuousScroll.data.initiallySelected || 0;
    }
    get initialItemHandle() {
        return this._groupContinuousScroll.data.initialItemHandle;
    }
    get itemLabelsTitle() {
        return this._groupContinuousScroll.title;
    }
    getLabelByIndex(index) {
        return this._groupContinuousScroll.data.groups[index].title;
    }
}
