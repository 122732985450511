import { AssetType } from '@sky-uk-ott/core-video-sdk-js';
import { get } from 'lodash';
import nielsenSubbrands from '../../constants/nielsenSubbrands';
import { PROGRAMMING_TYPES, CONTENT_TYPE } from '../../constants';
import TVPlatform from '../../lib/tv-platform';
import { isEpgProgram } from '../../store/PlayerStore/actions/epg';
import { isSingleProgram } from '../../store/PlayerStore/actions';
import { VideoAnalyticsEntitlement } from '../../graphql/generated/types';
const ADOBE_DEFAULT_VALUE_NONE = 'None';
const ADOBE_DEFAULT_VALUE_UNKNOWN = 'unknown';
const ADOBE_REQUESTOR_ID = 'nbcentertainment';
const getComscoreAssetMetadata = ({ lemonade, program, stream, }) => {
    var _a, _b;
    if (!program)
        return {};
    const epgProgram = isEpgProgram(program);
    const type = epgProgram &&
        {
            'Full Episode': AssetType.Episode,
            'Web Exclusive': AssetType.Clip,
            'Single Live Event': AssetType.Episode,
            Highlight: AssetType.Clip,
            Clip: AssetType.Clip,
            Trailer: AssetType.Clip,
            Movie: AssetType.Programme,
        }[program === null || program === void 0 ? void 0 : program.programmingType];
    return {
        availableAt: new Date((epgProgram ? program === null || program === void 0 ? void 0 : program.startTime : program === null || program === void 0 ? void 0 : program.airDate) || Date.now()),
        channelId: (lemonade.pid && `${lemonade.pid}`) ||
            (epgProgram && (program === null || program === void 0 ? void 0 : program.channelId)) ||
            (stream === null || stream === void 0 ? void 0 : stream.channelId),
        id: (lemonade === null || lemonade === void 0 ? void 0 : lemonade.adServerContentId) ||
            (program === null || program === void 0 ? void 0 : program.mpxGuid) ||
            (epgProgram && (program === null || program === void 0 ? void 0 : program.externalAdvertiserId)) ||
            (program === null || program === void 0 ? void 0 : program.tmsId) ||
            ((_b = (_a = lemonade.pid) === null || _a === void 0 ? void 0 : _a.toString) === null || _b === void 0 ? void 0 : _b.call(_a)) ||
            'N/A',
        seriesTitle: getSeriesTitleByProgrammingType(program),
        title: (program === null || program === void 0 ? void 0 : program.programTitle) || (!epgProgram && (program === null || program === void 0 ? void 0 : program.title)) || '',
        type: type || AssetType.Clip,
    };
};
const getConvivaAssetMetadata = ({ lemonade, program, product, isNBCProfileLinked, }) => ({
    adProvider: 'EMT',
    brand: get(program, 'brand.title'),
    product,
    profile: isNBCProfileLinked ? 'Free' : 'Unauthenticated',
    videoId: lemonade.pid || (program === null || program === void 0 ? void 0 : program.tmsId),
    isFromBackground: false,
    deviceId: TVPlatform.deviceId,
});
const getNielsenMetadata = ({ program, stream }) => {
    var _a;
    const singleProgram = isSingleProgram(program);
    const normalizedBrand = singleProgram ? (_a = program === null || program === void 0 ? void 0 : program.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().replace(' ', '') : '';
    const channelId = (!singleProgram && (program === null || program === void 0 ? void 0 : program.channelId)) || (stream === null || stream === void 0 ? void 0 : stream.channelId);
    return {
        // @ts- expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        subBrand: nielsenSubbrands[normalizedBrand] ||
            nielsenSubbrands[channelId] ||
            'c05',
    };
};
const getAdobeAssetMetadata = ({ program, stream }) => ({
    assetId: (program === null || program === void 0 ? void 0 : program.tmsId) || (program && 'pid' in program && (program === null || program === void 0 ? void 0 : program.pid)),
    genre: program === null || program === void 0 ? void 0 : program.genre,
    programmeStartEpoch: program && 'programStartTime' in program
        ? Math.floor(new Date(program === null || program === void 0 ? void 0 : program.programStartTime).getTime() / 1000)
        : Math.floor(Date.now() / 1000),
    league: (program === null || program === void 0 ? void 0 : program.league) || ADOBE_DEFAULT_VALUE_NONE,
    sport: (program === null || program === void 0 ? void 0 : program.sport) || ADOBE_DEFAULT_VALUE_NONE,
    videoClipType: program === null || program === void 0 ? void 0 : program.clipCategory,
    videoepnumber: ADOBE_DEFAULT_VALUE_UNKNOWN,
    videoLanguage: program && 'language' in program && (program === null || program === void 0 ? void 0 : program.language),
    videoPassGuid: (stream === null || stream === void 0 ? void 0 : stream.v4ID) || ADOBE_DEFAULT_VALUE_UNKNOWN,
    videoRequestorId: (program === null || program === void 0 ? void 0 : program.resourceId) || ADOBE_REQUESTOR_ID || ADOBE_DEFAULT_VALUE_UNKNOWN,
    adobeVideoResearchTitle: (program === null || program === void 0 ? void 0 : program.adobeVideoResearchTitle) || ADOBE_DEFAULT_VALUE_UNKNOWN,
    dayPart: program === null || program === void 0 ? void 0 : program.dayPart,
});
const getSubGenre = (program) => {
    var _a;
    const genre = get(program, 'analytics.secondaryGenre') || ((_a = program === null || program === void 0 ? void 0 : program.listOfGenres) === null || _a === void 0 ? void 0 : _a.split(', ')) || (program === null || program === void 0 ? void 0 : program.genre);
    return Array.isArray(genre) ? genre : genre ? [genre] : [];
};
const getChannelName = ({ program, stream }) => {
    if (get(stream, 'contentType') === CONTENT_TYPE.SLE)
        return;
    let channelName;
    if (get(stream, 'contentType') === CONTENT_TYPE.LINEAR) {
        channelName = stream === null || stream === void 0 ? void 0 : stream.brandDisplayTitle;
    }
    else {
        channelName = (program && 'channelId' in program && (program === null || program === void 0 ? void 0 : program.channelId)) || (stream === null || stream === void 0 ? void 0 : stream.channelId);
    }
    return { channelName };
};
export const getAssetMetadata = ({ lemonade, program, mvpd, product, isNBCProfileLinked, stream, sessionId, }) => {
    var _a, _b;
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, getConvivaAssetMetadata({
        lemonade,
        program,
        product,
        isNBCProfileLinked,
    })), getComscoreAssetMetadata({ lemonade, program, stream })), getAdobeAssetMetadata({ program, stream })), getNielsenMetadata({ program, stream })), getChannelName({ program, stream })), { appSessionId: sessionId, airedFirstAt: (program === null || program === void 0 ? void 0 : program.airDate)
            ? new Date(isNaN(Number(program.airDate)) ? program.airDate : Number(program.airDate))
            : new Date(), callSign: (program === null || program === void 0 ? void 0 : program.callSign) || undefined, 
        // @ts-expect-error FIXME: According to the type definition this should be a ContentType enum
        contentType: (lemonade === null || lemonade === void 0 ? void 0 : lemonade.pid)
            ? (program === null || program === void 0 ? void 0 : program.programmingType) === 'Single Live Event'
                ? 'SLE'
                : 'FER'
            : 'Live', duration: (program === null || program === void 0 ? void 0 : program.duration) || 0, entitled: (program === null || program === void 0 ? void 0 : program.locked) || undefined, episodeNumber: (program === null || program === void 0 ? void 0 : program.episodeNumber) ? Number(program === null || program === void 0 ? void 0 : program.episodeNumber) : undefined, episodeTitle: (program === null || program === void 0 ? void 0 : program.videoTitle) || (program && 'title' in program && (program === null || program === void 0 ? void 0 : program.title)) || '', graceId: (program === null || program === void 0 ? void 0 : program.tmsId) || (program === null || program === void 0 ? void 0 : program.titleTmsId) || 'None', isExclusiveChannel: false, isVodChannel: false, programmeId: ((_b = (_a = lemonade.pid) === null || _a === void 0 ? void 0 : _a.toString) === null || _b === void 0 ? void 0 : _b.call(_a)) || (program === null || program === void 0 ? void 0 : program.tmsId) || '', programmeTitle: getProgrammeTitleByProgrammingType(program), providerName: 'NBC', seasonNumber: (program === null || program === void 0 ? void 0 : program.seasonNumber) ? Number(program === null || program === void 0 ? void 0 : program.seasonNumber) : undefined, studioName: 'N/A', status: getVideoStatus(program), subGenre: getSubGenre(program), title: getTitleByProgrammingType(program), videoMvpd: (mvpd === null || mvpd === void 0 ? void 0 : mvpd.mvpd) || 'Unauthenticated', videoNetworkName: get(program, 'brand.title'), videoTmsId: (program === null || program === void 0 ? void 0 : program.tmsId) || (program === null || program === void 0 ? void 0 : program.titleTmsId) || 'None', videoBroadcast: (program && 'videoBroadcast' in program && (program === null || program === void 0 ? void 0 : program.videoBroadcast)) || 'None' });
};
const getSeriesTitleByProgrammingType = (program) => {
    const programTitle = program && 'title' in program ? program.title : '';
    switch (program === null || program === void 0 ? void 0 : program.programmingType) {
        case PROGRAMMING_TYPES.MOVIE:
            return (program === null || program === void 0 ? void 0 : program.movie) || programTitle || '';
        case PROGRAMMING_TYPES.FULL_EPISODE:
        case PROGRAMMING_TYPES.CLIP:
        case PROGRAMMING_TYPES.SNEAK_PEAK:
        case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
        case PROGRAMMING_TYPES.EXCERPT:
        case PROGRAMMING_TYPES.HIGHLIGHT:
            return (program === null || program === void 0 ? void 0 : program.series) || (program === null || program === void 0 ? void 0 : program.programTitle) || programTitle || '';
        case PROGRAMMING_TYPES.SFVOD:
            return (program === null || program === void 0 ? void 0 : program.league) || (program === null || program === void 0 ? void 0 : program.sport) || programTitle || '';
        case PROGRAMMING_TYPES.FER:
        case PROGRAMMING_TYPES.SLE:
            return (program === null || program === void 0 ? void 0 : program.programTitle) || programTitle || '';
        default:
            return (program === null || program === void 0 ? void 0 : program.programTitle) || programTitle || '';
    }
};
const getTitleByProgrammingType = (program) => {
    const programTitle = program && 'title' in program ? program.title : '';
    switch (program === null || program === void 0 ? void 0 : program.programmingType) {
        case PROGRAMMING_TYPES.MOVIE:
            return (program === null || program === void 0 ? void 0 : program.movie) || programTitle || '';
        case PROGRAMMING_TYPES.FULL_EPISODE:
        case PROGRAMMING_TYPES.CLIP:
        case PROGRAMMING_TYPES.SNEAK_PEAK:
        case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
        case PROGRAMMING_TYPES.EXCERPT:
        case PROGRAMMING_TYPES.HIGHLIGHT:
            return programTitle || '';
        case PROGRAMMING_TYPES.SFVOD:
        case PROGRAMMING_TYPES.FER:
        case PROGRAMMING_TYPES.SLE:
            return (program === null || program === void 0 ? void 0 : program.videoTitle) || programTitle || '';
        default:
            return (program === null || program === void 0 ? void 0 : program.videoTitle) || programTitle || '';
    }
};
const getProgrammeTitleByProgrammingType = (program) => {
    const programTitle = program && 'title' in program ? program.title : '';
    switch (program === null || program === void 0 ? void 0 : program.programmingType) {
        case PROGRAMMING_TYPES.MOVIE:
            return (program === null || program === void 0 ? void 0 : program.movie) || programTitle || '';
        case PROGRAMMING_TYPES.FULL_EPISODE:
        case PROGRAMMING_TYPES.CLIP:
        case PROGRAMMING_TYPES.SNEAK_PEAK:
        case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
        case PROGRAMMING_TYPES.EXCERPT:
        case PROGRAMMING_TYPES.HIGHLIGHT:
            return (program === null || program === void 0 ? void 0 : program.series) || '';
        case PROGRAMMING_TYPES.SFVOD:
            return (program === null || program === void 0 ? void 0 : program.programTitle) || (program === null || program === void 0 ? void 0 : program.videoTitle) || programTitle || '';
        case PROGRAMMING_TYPES.FER:
        case PROGRAMMING_TYPES.SLE:
            return (program === null || program === void 0 ? void 0 : program.videoTitle) || (program === null || program === void 0 ? void 0 : program.programTitle) || programTitle || '';
        default:
            return (program === null || program === void 0 ? void 0 : program.videoTitle) || programTitle || '';
    }
};
var VIDEO_STATUS;
(function (VIDEO_STATUS) {
    VIDEO_STATUS["UNRESTRICTED"] = "Unrestricted";
    VIDEO_STATUS["PREVIEW"] = "Preview";
    VIDEO_STATUS["RESTRICTED"] = "Restricted";
})(VIDEO_STATUS || (VIDEO_STATUS = {}));
const getVideoStatus = (program) => {
    if ((program === null || program === void 0 ? void 0 : program.programmingType) === PROGRAMMING_TYPES.PREVIEW) {
        return VIDEO_STATUS.PREVIEW;
    }
    else if (program && 'entitlement' in program && program.entitlement) {
        if (program.entitlement === VideoAnalyticsEntitlement.Free) {
            return VIDEO_STATUS.UNRESTRICTED;
        }
        else if (program.entitlement === VideoAnalyticsEntitlement.Entitled) {
            return VIDEO_STATUS.RESTRICTED;
        }
    }
};
