import { PlayerError } from '../../../components/error/PlayerError';
export var StreamLoaderErrors;
(function (StreamLoaderErrors) {
    StreamLoaderErrors["UNAUTHENTICATED"] = "UNAUTHENTICATED";
    StreamLoaderErrors["UNAUTHORIZED"] = "UNAUTHORIZED";
    StreamLoaderErrors["NO_CREDITS"] = "NO_CREDITS";
    StreamLoaderErrors["MISSING_DATA"] = "MISSING_DATA";
    StreamLoaderErrors["GEO"] = "GEO";
    StreamLoaderErrors["BLACKLIST"] = "BLACKLIST";
    StreamLoaderErrors["RETRANS"] = "RETRANS";
    StreamLoaderErrors["UNKNOWN"] = "UNKNOWN";
    StreamLoaderErrors["TEMP_PASS_REQUEST_FAILED"] = "TEMP_PASS_REQUEST_FAILED";
    StreamLoaderErrors["TEMP_PASS_EXPIRED"] = "TEMP_PASS_EXPIRED";
    StreamLoaderErrors["PARENTAL_CONTROLS"] = "PARENTAL_CONTROLS";
    StreamLoaderErrors["USER_CANCELLED"] = "USER_CANCELLED";
})(StreamLoaderErrors || (StreamLoaderErrors = {}));
const streamLoaderErrorFactory = (detail, data) => ({ detail, data });
export const unauthenticatedError = (data = {}) => streamLoaderErrorFactory(StreamLoaderErrors.UNAUTHENTICATED, data);
export const unauthorizedError = (data = {}) => streamLoaderErrorFactory(StreamLoaderErrors.UNAUTHORIZED, data);
export const parentalControlsError = (data = {}) => streamLoaderErrorFactory(StreamLoaderErrors.PARENTAL_CONTROLS, data);
export const tempPassExpiredError = streamLoaderErrorFactory(StreamLoaderErrors.TEMP_PASS_EXPIRED, {});
export const tempPassFailedError = streamLoaderErrorFactory(StreamLoaderErrors.TEMP_PASS_REQUEST_FAILED, {});
export const geoError = (error = {}) => streamLoaderErrorFactory(StreamLoaderErrors.GEO, error);
export const noCreditsError = (videoId) => streamLoaderErrorFactory(StreamLoaderErrors.NO_CREDITS, { videoId });
export const missingDataError = (error) => streamLoaderErrorFactory(StreamLoaderErrors.MISSING_DATA, error);
export const userCancelled = () => streamLoaderErrorFactory(StreamLoaderErrors.USER_CANCELLED, undefined);
export const StreamLoaderPlayerErrorMap = {
    [StreamLoaderErrors.GEO]: PlayerError.GEO,
    [StreamLoaderErrors.BLACKLIST]: PlayerError.AUTHZ,
    [StreamLoaderErrors.RETRANS]: PlayerError.RETRANS,
    [StreamLoaderErrors.UNAUTHORIZED]: PlayerError.AUTHZ,
    [StreamLoaderErrors.PARENTAL_CONTROLS]: PlayerError.PARENTAL_CONTROLS,
    [StreamLoaderErrors.UNKNOWN]: PlayerError.UNKNOWN,
};
export var StreamLoaderErrorSource;
(function (StreamLoaderErrorSource) {
    StreamLoaderErrorSource["CREDITS_FLOW"] = "creditsFlow";
})(StreamLoaderErrorSource || (StreamLoaderErrorSource = {}));
