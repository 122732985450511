import BaseCollection from './BaseCollection';
import { ShimmerLoader } from './index';
import { setSmooth } from '../helpers';
import { ScrollElementType } from '../util/contentPosition';
import { COMPONENT_TYPES, FONT_FACE, SHIMMER_LOADER_TILES } from '../constants';
import { SectionsSpawner } from '../api/spawners';
import { useRequest } from '../lib/useRequest';
import { ComponentsForPlaceholdersConfig, MixedEditorialConfig, onAirNowShelfConfig, } from '../helpers/request';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
export var PlaceholderReferrer;
(function (PlaceholderReferrer) {
    PlaceholderReferrer[PlaceholderReferrer["PLACEHOLDER"] = 0] = "PLACEHOLDER";
    PlaceholderReferrer[PlaceholderReferrer["MIX_EDITORIAL"] = 1] = "MIX_EDITORIAL";
    PlaceholderReferrer[PlaceholderReferrer["ON_AIR"] = 2] = "ON_AIR";
})(PlaceholderReferrer || (PlaceholderReferrer = {}));
export default class Placeholder extends BaseCollection {
    constructor() {
        super(...arguments);
        this._referrer = PlaceholderReferrer.PLACEHOLDER;
        this._initiallySelected = 0;
    }
    static _template() {
        return {
            TitleHolder: {
                Title: {
                    alpha: 0.87,
                    text: { fontFace: FONT_FACE.light, fontSize: 40 },
                },
            },
            Items: {
                y: 120,
                forceZIndexContext: true,
                boundsMargin: [500, 100, 500, 100],
            },
        };
    }
    _init() {
        this._index = 0;
        /** Initialize the placeholder section with empty tiles until data items are set */
        this.tag('Items').childList.a(this.createEmptyLoaderTiles());
    }
    set initiallySelected(initiallySelected) {
        this._initiallySelected = initiallySelected;
    }
    set initialItemHandle(initialItemHandle) {
        if (!initialItemHandle)
            return;
        this._initialItemHandle = initialItemHandle;
    }
    set queryVariables(v) {
        this._queryVariables = v;
    }
    _detach() {
        var _a;
        super._detach();
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    async _enable() {
        super._enable();
        if (!this._queryVariables)
            return;
        try {
            const request = (() => {
                switch (this._referrer) {
                    case PlaceholderReferrer.ON_AIR:
                        return useRequest(onAirNowShelfConfig(this._queryVariables));
                    case PlaceholderReferrer.MIX_EDITORIAL:
                        return useRequest(MixedEditorialConfig(this._queryVariables));
                    default:
                        return useRequest(ComponentsForPlaceholdersConfig(this._queryVariables));
                }
            })();
            const response = await request.fetch();
            this.setPlaceholderData({
                data: response && 'components' in response ? response === null || response === void 0 ? void 0 : response.components : [response === null || response === void 0 ? void 0 : response.data],
            });
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                description: 'Placeholder Request Error',
                payload: e,
            });
        }
    }
    set referrer(v) {
        this._referrer = v;
    }
    createEmptyLoaderTiles() {
        const style = this.getStyle();
        if (!style) {
            console.warn('Placeholder.js missing style. Check ./src/lib/style.js');
            return;
        }
        this.noOfItemsToScroll = style.noOfListItemsToScroll;
        const emptyLoaderItems = [];
        for (let i = 0; i <= SHIMMER_LOADER_TILES; i++) {
            emptyLoaderItems.push({
                type: ShimmerLoader,
                itemType: 'videoTile',
                item: { w: 420, h: 235 },
                index: i,
                x: i * style.item.w,
                y: 70,
            });
        }
        return emptyLoaderItems;
    }
    _handleBack(e) {
        if (this._index === 0) {
            return false;
        }
        else {
            this.setIndex(0);
            e.preventDefault();
            e.stopPropagation();
        }
    }
    setIndex(index) {
        this._index = index;
        const style = this.getStyle();
        const noOfItems = this.noOfItemsToScroll || 4;
        setSmooth(this.tag('Items'), 'x', index > noOfItems - 1 ? (index - (noOfItems - 1)) * -style.item.w : 0, {
            duration: 0.3,
            delay: 0,
        });
        this.fireAncestors('$scrolledListItemIndex', { index });
        this._refocus();
    }
    _getFocused() {
        return this.activeItemWrapper;
    }
    async setPlaceholderData(v) {
        var _a;
        if (!((_a = v === null || v === void 0 ? void 0 : v.data) === null || _a === void 0 ? void 0 : _a.length)) {
            this.fireAncestors('$onEmptyWrapperList', this);
            return;
        }
        const data = v.data.map((_section) => {
            if ((_section === null || _section === void 0 ? void 0 : _section.component) === COMPONENT_TYPES.GROUPED_CONTINUOUS_SCROLL) {
                _section = Object.assign({}, _section);
                _section.data.initiallySelected = this._initiallySelected;
                _section.data.initialItemHandle = this._initialItemHandle;
            }
            return _section;
        });
        const sections = await SectionsSpawner(this.stage, data);
        if ((sections === null || sections === void 0 ? void 0 : sections.length) || 0 > 0) {
            this.fireAncestors('$onPlaceholderShelfChange', this, sections);
        }
        else {
            this.fireAncestors('$onEmptyWrapperList', this);
        }
    }
    set items(v) {
        this.tag('Items').childList.clear();
        this.stage.gc();
        this._items = v;
        const itemsToRender = this.create({ items: this._items });
        this.tag('Items').patch({
            children: itemsToRender,
        });
    }
    _removeItem(itemToRemove) {
        this.tag('Items').childList.remove(itemToRemove);
        if (this.tag('Items').childList.length === 0) {
            this.fireAncestors('$onEmptyWrapperList', this);
        }
        this._repositionItems();
        this.setIndex(0);
    }
    _repositionItems() {
        const style = this.getStyle();
        this.tag('Items').childList.forEach((item, index) => {
            item.patch({
                x: index * style.item.w,
            });
        });
    }
    set title(v) {
        if (v) {
            this.tag('Title').text = v;
        }
        else {
            this.tag('Items').patch({
                y: 0,
            });
        }
    }
    set noOfItemsToScroll(noOfItems) {
        this._noOfItemsToScroll = noOfItems;
    }
    get noOfItemsToScroll() {
        return this._noOfItemsToScroll || 4;
    }
    $getCurrentScrollPosition() {
        const position = this.fireAncestors('$getCurrentScrollPosition');
        return {
            type: ScrollElementType.LIST,
            row: position.row || 0,
            instanceID: position.instanceID,
        };
    }
}
