import { Storage } from '@lightningjs/sdk';
import { CONTENT_TYPE, STORAGE_KEYS } from '../../../../constants';
import { getAdjustedReferringShelf, getDurationSinceLaunch } from '../../../../helpers';
import { EVENTS } from '../../../../lib/analytics/types';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import RouterUtil from '../../../../util/RouterUtil';
import { sendMetric } from '../../../../lib/analytics/Analytics';
import AuthenticationSingleton from '../../../../authentication/Authentication';
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { useProgress } from '../../../../components/player/PlayerControls/hooks/useProgress';
import { LiveStreamManager } from '../../../../lib/LiveStreamManager';
export class AnalyticsDelegate {
    constructor(ctx) {
        this._ctx = ctx;
    }
    sendMetric(name, payload) {
        return sendMetric(name, payload);
    }
    _getAnalyticsData() {
        return {};
    }
    _getMetricsData() {
        var _a;
        const { stream, program, lemonade, geo } = PlayerStoreSingleton;
        if (!program || !stream)
            return {};
        const storageReferringShelf = Storage.get(STORAGE_KEYS.REFERRING_SHELF);
        const smartTile = Storage.get(STORAGE_KEYS.SMART_TILE);
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        const referringShelf = getAdjustedReferringShelf(storageReferringShelf !== null && storageReferringShelf !== void 0 ? storageReferringShelf : {});
        const pid = (lemonade === null || lemonade === void 0 ? void 0 : lemonade.pid) || ('pid' in stream && (stream === null || stream === void 0 ? void 0 : stream.pid)) || '';
        const isSle = this._ctx._stream_type === CONTENT_TYPE.SLE;
        const duration = isSle || !('duration' in program && typeof program.duration === 'number')
            ? 0
            : program === null || program === void 0 ? void 0 : program.duration;
        const durationInMilliseconds = 'durationInMilliseconds' in program && typeof program.durationInMilliseconds === 'number'
            ? program === null || program === void 0 ? void 0 : program.durationInMilliseconds
            : duration * 1000;
        const durationSinceLaunch = this._ctx._stream_type === CONTENT_TYPE.FER && getDurationSinceLaunch();
        const progress = (_a = useProgress().value) === null || _a === void 0 ? void 0 : _a[0];
        const liveWatchProgressMs = LiveStreamManager.startTime
            ? new Date().getTime() - LiveStreamManager.startTime
            : 0;
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, program), geo), stream), { pid, path: RouterUtil.current(), streamUrl: (lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrl) || (lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrls) || '', ccLanguage: ClosedCaptionsUtils.getCCType() }), (this._ctx._programOrder ? { programOrder: this._ctx._programOrder } : {})), (progress ? { linearDuration: progress, watched: progress } : {})), { shelf: referringShelf, duration,
            durationInMilliseconds, resumeTime: 0, durationSinceLaunch,
            smartTile, authType: this._ctx.authtype, liveWatchProgress: liveWatchProgressMs && !isNaN(liveWatchProgressMs) ? liveWatchProgressMs / 1000 : 0, initialTempPassRequest: this._ctx.initialTempPassRequest });
    }
    getErrorAnalytics(error, errorEvent) {
        var _a;
        const mvpd = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.mvpd;
        const code = (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.code) || (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.type);
        const description = errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.description;
        const err = new Error();
        return {
            player: this._player,
            video: this._getMetricsData(),
            errorType: 'Video',
            description,
            friendlyMessage: error.message,
            code,
            mvpd: mvpd,
            source: this._stream_type,
            system: 'Other',
            stack: err.stack,
            fatal: true,
            isLive: true,
        };
    }
    firePageLoad() {
        sendMetric(EVENTS.PAGE_LOAD, this._getMetricsData());
    }
    firePlayerReady() {
        sendMetric(EVENTS.PLAYER_READY);
    }
    fireClick(name) {
        var _a, _b;
        const { stream, program } = PlayerStoreSingleton;
        if (!stream || !program)
            return;
        const brand = 'brand' in program ? (_a = program.brand) === null || _a === void 0 ? void 0 : _a.title : stream === null || stream === void 0 ? void 0 : stream.brandDisplayTitle;
        const show = ('series' in program && program.series) ||
            ('seriesShortTitle' in stream && stream.seriesShortTitle) ||
            '';
        const season = 'seasonNumber' in stream ? stream.seasonNumber : '';
        const video = 'mpxGuid' in stream ? stream.mpxGuid : '';
        sendMetric(EVENTS.CLICK, {
            name,
            brand,
            show,
            season,
            video,
            shelfTitle: (_b = getAdjustedReferringShelf()) === null || _b === void 0 ? void 0 : _b.listTitle,
        });
    }
    fireContentClick(payload) {
        sendMetric(EVENTS.CONTENT_CLICK, payload);
    }
    fireSessionInit() {
        sendMetric(EVENTS.LIVE_SESSION_INITIALIZED);
    }
    fireSessionStart() {
        LiveStreamManager.setStartTime();
        sendMetric(EVENTS.LIVE_SESSION_START, this._getMetricsData());
    }
    fireSessionEnd() {
        sendMetric(EVENTS.LIVE_SESSION_END, this._getMetricsData());
        LiveStreamManager.resetStartTime();
    }
    fireSeekStart() {
        sendMetric(EVENTS.SEEK_START);
    }
    fireSeekEnd() {
        sendMetric(EVENTS.SEEK_END);
    }
    fireVideoStart(data = {}) {
        sendMetric(EVENTS.VOD_SESSION_START, Object.assign(Object.assign({}, this._getMetricsData()), data));
    }
    fireVideoEnd(data = {}) {
        sendMetric(EVENTS.VOD_SESSION_END, Object.assign(Object.assign({}, this._getMetricsData()), data));
    }
}
