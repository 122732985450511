import { Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../../../../../constants';
export class SecondaryControlButton extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._bgColor = COLORS.dark;
    }
    static _template() {
        return {
            Shader: {
                rect: true,
            },
            Label: {
                mountY: 0.5,
                text: {
                    fontSize: 30,
                    text: this.bindProp('label'),
                    textColor: COLORS.white,
                    fontFace: FONT_FACE.regular,
                },
            },
            Icon: { mountY: 0.5, x: 0, y: 0 },
        };
    }
    set icon(src) {
        if (!src)
            return;
        this.tag('Icon').patch({
            src: Utils.asset(src),
            w: 28,
        });
    }
    _init() {
        this.tag('Label').on('txLoaded', this._redraw.bind(this));
        this.tag('Icon').on('txLoaded', this._redraw.bind(this));
    }
    _focus() {
        this._setColors(COLORS.white, COLORS.dark);
    }
    _unfocus() {
        this._setColors(this._bgColor, COLORS.white);
    }
    set bgColor(color) {
        if (color)
            this._bgColor = color;
    }
    _redraw() {
        // button height
        const yButtonPadding = 5;
        const height = this.tag('Label').renderHeight + yButtonPadding;
        const middleY = height / 2;
        // button label width plus gap between icon and label
        const xGap = 10;
        const labelWidth = this.tag('Label').renderWidth + xGap;
        const xButtonPadding = 25;
        const iconWidth = this.tag('Icon').renderWidth;
        // button width
        const totalWidth = iconWidth + labelWidth + xButtonPadding * 2;
        this.patch({
            w: totalWidth,
            h: height,
            Shader: {
                w: totalWidth,
                h: height,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: middleY,
                    fillColor: this._bgColor,
                },
            },
            Label: {
                x: iconWidth + xGap + xButtonPadding,
                y: middleY + 2,
                color: COLORS.white,
            },
            Icon: {
                x: xButtonPadding,
                y: middleY + 1,
            },
        });
    }
    _setColors(fillColor, color) {
        this.patch({
            Shader: {
                shader: {
                    fillColor,
                },
            },
            Label: {
                color,
            },
            Icon: {
                color,
            },
        });
    }
}
