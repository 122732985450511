import { getPlatformName, setDefaultValue } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT } from '../../../../constants';
const getEntitlement = (data) => {
    if (data === null || data === void 0 ? void 0 : data.LiveEntitlement)
        return data.LiveEntitlement;
    if (typeof (data === null || data === void 0 ? void 0 : data.locked) === 'boolean') {
        return data.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE;
    }
    return MPARTICLE_DEFAULT_ATTR_VALUE;
};
// VOD Asset || Live stream || Shows || Default
const getBrand = (data) => { var _a; return (data === null || data === void 0 ? void 0 : data.brandDisplayTitle) || ((_a = data === null || data === void 0 ? void 0 : data.brand) === null || _a === void 0 ? void 0 : _a.title) || (data === null || data === void 0 ? void 0 : data.brand) || MPARTICLE_DEFAULT_ATTR_VALUE; };
/**
 *
 * @function getShow
 * @param {object} data - pageLoadAttributes
 * @returns {string} fisrt valid Show value from expected nodes
 */
const getShow = (data) => data.show || data.series || data.seriesShortTitle || data.shortTitle || data.programTitle;
const getPageLoadAttributes = (params = {}) => setDefaultValue({
    'Sweepstakes Name': '',
    'Registration Referrer': params.regRef || '',
    // Device Type per specs.
    'Registration Source': getPlatformName(),
    Brand: getBrand(params),
    Show: getShow(params),
    Season: params.seasonNumber,
    'Video Id': params.pid || params.mpxGuid || params.tmsId,
    'Video Type': params.videoType || params.programmingType || params.programType,
    Entitlement: getEntitlement(params),
    'Episode Title': params.programTitle || params.title,
    'Episode Number': params.episodeNumber,
    'Video Duration': params.durationInMilliseconds || MPARTICLE_DEFAULT_ATTR_VALUE,
    Category: params.category,
    URL: params.hash,
    'Profile Referer': '',
    Genre: params.genres || params.genre,
    'Secondary Genre': (Array.isArray(params.secondaryGenres)
        ? params.secondaryGenres[0]
        : params.secondaryGenre) || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Has Trailer': params.hasTrailer,
    'Smart Speaker': '',
    Intent: '',
    'Page Load Duration': '',
    Sport: params.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
    League: params.league || MPARTICLE_DEFAULT_ATTR_VALUE,
}, MPARTICLE_DEFAULT_ATTR_VALUE);
export default getPageLoadAttributes;
