import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT } from '../../../../constants';
export const getEndCardRecommendationsAttributes = ({ recommendation, recommendationAlternate1, }) => {
    return {
        'Recommendation Show': recommendation === null || recommendation === void 0 ? void 0 : recommendation.show,
        'Recommendation Sport': recommendation === null || recommendation === void 0 ? void 0 : recommendation.sport,
        'Recommendation League': recommendation === null || recommendation === void 0 ? void 0 : recommendation.league,
        'Recommendation Video ID': recommendation === null || recommendation === void 0 ? void 0 : recommendation.mpxGuid,
        'Recommendation Video Type': recommendation === null || recommendation === void 0 ? void 0 : recommendation.videoType,
        'Recommendation Episode Title': recommendation === null || recommendation === void 0 ? void 0 : recommendation.episodeTitle,
        'Recommendation Entitlement': (recommendation === null || recommendation === void 0 ? void 0 : recommendation.locked) ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
        'Recommendation Brand': recommendation === null || recommendation === void 0 ? void 0 : recommendation.brand,
        'Recommendation Genre': (recommendation === null || recommendation === void 0 ? void 0 : recommendation.genre) || MPARTICLE_DEFAULT_ATTR_VALUE,
        'End Card Recommendation Type': recommendation === null || recommendation === void 0 ? void 0 : recommendation.recommendationType,
        'Alt 1 Recommendation Show': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.show,
        'Alt 1 Recommendation Sport': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.sport,
        'Alt 1 Recommendation League': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.league,
        'Alt 1 Recommendation Video ID': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.mpxGuid,
        'Alt 1 Recommendation Video Type': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.videoType,
        'Alt 1 Recommendation Episode Title': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.episodeTitle,
        'Alt 1 Recommendation Entitlement': (recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.locked)
            ? ENTITLEMENT.ENTITLED
            : ENTITLEMENT.FREE,
        'Alt 1 Recommendation Brand': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.brand,
        'Alt 1 Recommendation Genre': (recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.genre) || MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 1 End Card Recommendation Type': recommendationAlternate1 === null || recommendationAlternate1 === void 0 ? void 0 : recommendationAlternate1.recommendationType,
        'Alt 2 Recommendation Show': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Sport': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation League': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Video Type': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Episode Title': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 Recommendation Brand': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Alt 2 End Card Recommendation Type': MPARTICLE_DEFAULT_ATTR_VALUE,
    };
};
export const getEndCardCommonAttributes = ({ video, endCardTime, shelf }) => {
    return {
        'Video Duration': video.durationInMilliseconds,
        'End Card Time': endCardTime,
        'Playlist Name': shelf === null || shelf === void 0 ? void 0 : shelf.playlistMachineName,
        'Playlist Position': shelf === null || shelf === void 0 ? void 0 : shelf.playlistPosition,
    };
};
