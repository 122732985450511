import { Router } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import LongScrollPage from '../LongScrollPage';
import { setSmooth, templateDeepMerge } from '../../helpers';
import { createItems } from '../../components/componentMaps';
import TileDataModels from '../../api/models/tiles';
import { ResultsStateFactory } from './states/Results';
import { FiltersStateFactory } from './states/Filters';
import { COLORS, ROUTE } from '../../constants';
import ContentPosition from '../../util/contentPosition';
import FilterLabelsList from '../../components/labelsList/FiltersLabelsList';
import { LabelsListRenderingType } from '../../components/labelsList/LabelsList';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
// @ts-expect-error TS(2417): Class static side 'typeof BaseAllEvents' incorrect... Remove this comment to see the full error message
export default class BaseAllEvents extends LongScrollPage {
    constructor() {
        super(...arguments);
        this._canFetchMoreDates = false;
        this._currentIndex = 0;
        this._dates = [];
        this._datesPage = 0;
        this._isFetchingDates = false;
        this._isFetchingResults = false;
        this._pageTitle = '';
        this._parentRoute = '';
        this._resultsPage = 0;
        this._timeParams = null;
        this._assetsFetchFunction = () => Promise.resolve();
        this._datesFetchFunction = () => Promise.resolve();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this._assetsFetchByIndexFunction = (_index) => Promise.resolve();
        this.fetchByFilter = (timeParams) => {
            const keys = ['localStartTimestamp', 'localEndTimestamp'];
            const isSameFilter = keys.every((key) => { var _a; return ((_a = this._timeParams) === null || _a === void 0 ? void 0 : _a[key]) === (timeParams === null || timeParams === void 0 ? void 0 : timeParams[key]); });
            // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
            this._assetsFetchFunction(isSameFilter ? null : timeParams)
                .then((v) => {
                // @ts-expect-error TS(2339): Property 'assets' does not exist on type 'void'.
                if (v.assets.length) {
                    this.apiData = v;
                    setSmooth(this.tag('Results'), 'visible', 1);
                    if (isSameFilter) {
                        this._filters.selectItem(0);
                    }
                }
                setSmooth(this.widgets.loader, 'visible', 0);
            })
                .catch((err) => {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    code: 'All Events Page',
                    description: 'Error fetching search results',
                    payload: err,
                });
                Router.navigate(ROUTE.error);
            });
        };
    }
    static _template(override) {
        return templateDeepMerge({
            Filters: {
                x: 75,
                y: 184,
                type: FilterLabelsList,
                mode: LabelsListRenderingType.HORIZONTAL,
                focusFontFace: 'Regular',
                radius: 6,
            },
            Results: {},
        }, override);
    }
    async _init() {
        const position = ContentPosition.getPositionForCurrentPage();
        this._filters = this.tag('Filters');
        try {
            this.dates = await this._datesFetchFunction();
            if (position && (position === null || position === void 0 ? void 0 : position.content) && (position === null || position === void 0 ? void 0 : position.content) >= 16) {
                // Fetch enough data to load a saved a position
                this.indexApiData = await this._assetsFetchByIndexFunction(position === null || position === void 0 ? void 0 : position.content);
            }
            else if (!this.initialDateParams) {
                this.apiData = await this._assetsFetchFunction();
            }
            else {
                this.fetchByFilter(this.initialDateParams);
            }
        }
        catch (_a) {
            //fail silently
        }
    }
    _attach() {
        this.stage.setClearColor(COLORS.dark);
    }
    set results(v) {
        if (v) {
            this._results = v;
            v.x = 75;
            v.y = 267;
            this.patch({
                Results: v,
            });
        }
        else {
            this.patch({
                Results: undefined,
            });
        }
    }
    get results() {
        return this._results;
    }
    set params(params) {
        const mmddyyyy = (params === null || params === void 0 ? void 0 : params.mmddyyyy) || this.getDateFromLocationHash();
        if (mmddyyyy) {
            const date = moment(mmddyyyy, 'MMDDYYYY');
            this._initialDate = {
                value: date.format('YYYY-MM-DD'),
                startUnix: date.endOf('day').unix(),
                endUnix: date.startOf('day').unix(),
            };
        }
    }
    get initialDateParams() {
        if (!this._initialDate)
            return null;
        return {
            localEndTimestamp: this._initialDate.endUnix,
            localStartTimestamp: this._initialDate.startUnix,
        };
    }
    getDateFromLocationHash() {
        var _a;
        const date = (_a = window.location.hash.split('/')) === null || _a === void 0 ? void 0 : _a[1];
        if (date && /(^\d{8}$)/.test(date))
            return date;
    }
    _onMounted() {
        this.stage.setClearColor(COLORS.dark);
        this.widgets.menu.collapse({
            parentRoute: this._parentRoute,
            subCategoryItem: this._pageTitle,
            hideProfile: true,
        });
    }
    _disable() {
        this.widgets.menu.expand();
    }
    _focusChange() {
        this._currentIndex = this.tag('Results').index;
    }
    _handleBack() {
        if (!this._currentIndex) {
            return false;
        }
        else {
            this.tag('Results').setIndex(0);
            return true;
        }
    }
    _createDatesPatchObject(dates, activeIndex) {
        return dates.map(({ label }, index) => {
            return {
                active: typeof activeIndex === 'number' && index === activeIndex,
                label,
                fontSize: 35,
            };
        });
    }
    _createResultsPatchObject(assets) {
        return createItems(assets).reduce((acc, v) => {
            var _a;
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const model = TileDataModels[((_a = v.item) === null || _a === void 0 ? void 0 : _a.component) || ''];
            return model
                ? [
                    ...acc,
                    Object.assign(Object.assign({}, v), { item: new model(v.item) }),
                ]
                : acc;
        }, []);
    }
    _setDatesPage(v) {
        this._datesPage = v.page;
        this._canFetchMoreDates = v.totalPages > v.page;
    }
    _setResultsPage(v) {
        this._resultsPage = v.page;
        this.results.moreItems = v.totalPages > v.page;
    }
    $getMoreItems() {
        if (this._isFetchingResults)
            return;
        this._isFetchingResults = true;
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
        this._assetsFetchFunction(this._timeParams, this._resultsPage + 1).then((results) => {
            this._isFetchingResults = false;
            const { assets } = results || {};
            if (assets && assets.length > 0) {
                this._setResultsPage(results);
                this.results.addItems(this._createResultsPatchObject(assets));
            }
        });
    }
    $onLabelsPageEnd() {
        var _a;
        if (this._isFetchingDates || !this._canFetchMoreDates)
            return;
        this._isFetchingDates = true;
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        (_a = this._datesFetchFunction(this._datesPage + 1)) === null || _a === void 0 ? void 0 : _a.then((v) => {
            this._isFetchingDates = false;
            this._setDatesPage(v);
            // @ts-expect-error TS(2339): Property 'dates' does not exist on type 'void'.
            this._dates = this._dates.concat(v.dates);
            // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
            this._filters.addItems(this._createDatesPatchObject(v.dates));
        });
    }
    $scrolledRow(rowId) {
        const hideElements = rowId < 2;
        this.widgets.menu.visible = hideElements;
        this.tag('Filters').patch({ visible: hideElements });
        if (this.tag('Results').children.length)
            this._setState('Results');
    }
    _fillInitialDate() {
        const findDate = ({ value }) => value === this._initialDate.value;
        const datesIndex = this._dates.findIndex(findDate);
        if (datesIndex > -1) {
            const { localEndTimestamp, localStartTimestamp } = this._dates[datesIndex];
            this._initialDate.endUnix = localEndTimestamp;
            this._initialDate.startUnix = localStartTimestamp;
            return datesIndex;
        }
        return 0;
    }
    static _states() {
        return [ResultsStateFactory(this), FiltersStateFactory(this)];
    }
}
