import TVPlatform from '../tv-platform';
import { splitTextToFitLength } from '../../helpers';
class SpeechController {
    constructor() {
        this._active = false;
        this._voiceOutDisabled = false;
        this._announces = [];
        this._tts = TVPlatform.tts();
    }
    get active() {
        return this._active;
    }
    get voiceOutDisabled() {
        return this._voiceOutDisabled;
    }
    append(toSpeak, notification = false) {
        const seriesArray = toSpeak.flatMap((announce) => {
            return (this._normalize(Array.isArray(announce) ? announce : [announce]))
                .filter((item) => item.length);
        }).filter((item) => !!item && item.length);
        const inlineText = seriesArray.join('\n\n');
        if (!inlineText.length)
            return;
        if (this._tts.chunkLength && inlineText.length <= this._tts.chunkLength) {
            this._announces.push(this._tts.speak(inlineText, notification));
            return;
        }
        for (const phrase of seriesArray) {
            this._announces.push(this._tts.speak(phrase, notification));
        }
    }
    cancel() {
        this._announces = [];
        this._tts.cancel();
        this._active = false;
        this._voiceOutDisabled = false;
    }
    async speak(series, notification = false) {
        this._active = true;
        this.append(series, notification);
        await this._speakSeries();
    }
    async _speakSeries() {
        let series;
        while (this._announces.length) {
            series = this._announces.shift();
            if (!series)
                continue;
            this._voiceOutDisabled = series.notification;
            this._lastPhrase = series.phrase;
            await series.speak();
        }
        if (!series || this._lastPhrase === series.phrase) {
            this._voiceOutDisabled = false;
            this._active = false;
        }
    }
    _normalize(phrasesArray) {
        let normalizedPhrasesArray = [];
        for (const string of phrasesArray) {
            if (Array.isArray(string)) {
                normalizedPhrasesArray.push(this._normalize(string).join(' '));
            }
            else {
                const phrase = typeof string === 'function' ? string() : string;
                const result = this._tts.chunkLength
                    ? splitTextToFitLength(phrase, this._tts.chunkLength)
                    : phrase;
                if (Array.isArray(result)) {
                    normalizedPhrasesArray.push(...result);
                }
                else {
                    normalizedPhrasesArray.push(result);
                }
            }
        }
        return normalizedPhrasesArray;
    }
}
export default new SpeechController();
