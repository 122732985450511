import { Log } from '@lightningjs/sdk';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import SpeechController from './SpeechController';
export var AnnouncerEvent;
(function (AnnouncerEvent) {
    AnnouncerEvent["TTS_START"] = "announceStarted";
    AnnouncerEvent["TTS_END"] = "announceEnded";
})(AnnouncerEvent || (AnnouncerEvent = {}));
const ANNOUNCER_TAG = 'Announcer';
class Announcer {
    constructor() {
        this.enabled = false;
        this.debug = false;
        this.announcerTimeout = 300 * 1000;
        this.announcerFocusDebounce = 400;
        this.speechController = SpeechController;
        this.prevFocusPath = [];
        this._events = new Subject();
        this.onFocusChange = debounce((focusPath = []) => {
            if (!this.enabled)
                return;
            const lastFocusPath = this.prevFocusPath || [];
            const loaded = focusPath.every((elm) => !elm.loading);
            const focusDiff = focusPath.filter((elm) => !lastFocusPath.includes(elm));
            if (!loaded) {
                this.debounceAnnounceFocusChange();
                return;
            }
            this.prevFocusPath = focusPath.slice(0);
            const orderedAnnouncement = [];
            const reversedArray = [...focusDiff].reverse();
            reversedArray.reduce((acc, elm) => {
                const elName = Announcer.getElementName(elm);
                if (elm.announceContext) {
                    acc.push([elName, 'Context', elm.announceContext]);
                }
                else {
                    acc.push([elName, 'No Context', '']);
                }
                return acc;
            }, orderedAnnouncement);
            focusDiff.reduce((acc, elm) => {
                const elName = Announcer.getElementName(elm);
                if (elm.announce) {
                    acc.push([elName, 'Announce', elm.announce || '']);
                }
                else if (elm.title) {
                    acc.push([elName, 'Title', elm.title || '']);
                }
                else {
                    acc.push([elName, 'Label', elm.label || '']);
                }
                return acc;
            }, orderedAnnouncement);
            if (this.debug)
                Log.info(ANNOUNCER_TAG, orderedAnnouncement);
            const toAnnounce = orderedAnnouncement.map((item) => item.pop()).filter((item) => !!item && (typeof item !== 'string' || item.length));
            if (toAnnounce.length) {
                if (this.speechController.voiceOutDisabled && this.speechController.active) {
                    this.speechController.append(toAnnounce);
                    return;
                }
                this.voiceOut(toAnnounce);
            }
        }, this.announcerFocusDebounce);
        this._onError = (error) => {
            // Won't TVPlatform.reportError since it will send a lot of unneeded events
            if (this.debug)
                Log.error(ANNOUNCER_TAG, error);
        };
        this.announceEnded = () => {
            this.emit(AnnouncerEvent.TTS_END);
        };
        this.debounceAnnounceFocusChange = debounce(this.onFocusChange.bind(this), this.announcerFocusDebounce, { leading: false, trailing: false });
        this.resetFocusTimer = debounce(() => {
            this.prevFocusPath = [];
        }, this.announcerTimeout, { leading: false, trailing: false });
    }
    static getElementName(elm) {
        return elm.ref || elm.constructor.name;
    }
    announce(toAnnounce, { append = false, notification = false } = {}) {
        if (!this.enabled)
            return;
        this.debounceAnnounceFocusChange.flush();
        if (append && this.speechController.active && !notification) {
            this.speechController.append([toAnnounce]);
        }
        else {
            this.voiceOut([toAnnounce], notification);
        }
    }
    get events() {
        return this._events;
    }
    subscribe(handler) {
        if (this.enabled)
            return this._events.subscribe(handler);
    }
    emit(type) {
        this.events.next({
            type,
        });
    }
    stop() {
        this.announceEnded();
        this.resetFocusTimer();
        this.cancel();
    }
    voiceOut(toAnnounce, notification = false) {
        this.stop();
        this.emit(AnnouncerEvent.TTS_START);
        this.speechController
            .speak(toAnnounce, notification)
            .catch(this._onError)
            .finally(() => {
            this.announceEnded();
        });
    }
    cancel() {
        this.speechController.cancel();
    }
}
export default new Announcer();
