import { Language } from '@lightningjs/sdk';
import { setSmooth } from '../../../../helpers';
import { COLORS, FONT_FACE } from '../../../../constants';
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils';
import Preferences from '../../../../lib/Preferences';
import SelectButton from '../../../buttons/SelectButton';
import CCColorSelectButton from '../../../buttons/CCColorSelectButton';
import { CCSettingsItems } from '../index';
export const CaptionsSettingsStateFactory = (base) => class CaptionsSettings extends base {
    $enter() {
        setSmooth(this._main, 'alpha', 0);
        setSmooth(this._captionsSettingsContainer, 'alpha', 1);
        this._captionsAppearanceContainer
            .animation({
            duration: 0.3,
            repeat: 0,
            stopMethod: 'immediate',
            actions: [{ p: 'x', v: { 0: 590, 1: 0 } }],
        })
            .start();
        this._appearanceListIndex = 0;
        this._captionsAppearanceList.y = 0;
    }
    $exit() {
        setSmooth(this._captionsSettingsContainer, 'alpha', 0);
        setSmooth(this._main, 'alpha', 1);
        this._main
            .animation({
            duration: 0.3,
            repeat: 0,
            stopMethod: 'immediate',
            actions: [{ p: 'x', v: { 0: -500, 1: 0 } }],
        })
            .start();
    }
    static _states() {
        return [
            class Appearance extends CaptionsSettings {
                $enter() {
                    setSmooth(this._captionsAppearanceContainer, 'alpha', 1);
                    setSmooth(this._captionsSettingContainer, 'alpha', 0);
                }
                _handleBackButton() {
                    this._setState('Main.CaptionAppearanceButton');
                    return true;
                }
                static _states() {
                    return [
                        class Back extends Appearance {
                            $enter() {
                                this._backButton.color = COLORS.white;
                            }
                            $exit() {
                                this._backButton.color = COLORS.mediumGray3;
                            }
                            _handleDown() {
                                this._setState('CaptionsSettings.Appearance.Reset');
                            }
                            _handleEnter() {
                                this._setState('Main.CaptionAppearanceButton');
                            }
                        },
                        class Reset extends Appearance {
                            _getFocused() {
                                return this._resetButton || this;
                            }
                            _handleUp() {
                                this._setState('CaptionsSettings.Appearance.Back');
                            }
                            _handleDown() {
                                this._setState('CaptionsSettings.Appearance.List');
                            }
                            _handleEnter() {
                                ClosedCaptionsUtils.resetSettings();
                                this._updateAppearanceButtons();
                                this.fireAncestors('$resetCCSettings');
                            }
                        },
                        class List extends Appearance {
                            get focusItem() {
                                return this.getChildItem(this._appearanceListIndex);
                            }
                            getChildItem(index = 0) {
                                return this._captionsAppearanceList.children[index];
                            }
                            _getFocused() {
                                return this.focusItem || this;
                            }
                            _handleUp() {
                                if (this._appearanceListIndex > 0) {
                                    this._appearanceListIndex--;
                                    this._scrollList();
                                }
                                else {
                                    this._setState('CaptionsSettings.Appearance.Reset');
                                }
                            }
                            _handleDown() {
                                if (this._appearanceListIndex <
                                    this._captionsAppearanceList.children.length - 1) {
                                    this._appearanceListIndex++;
                                    this._scrollList();
                                }
                            }
                            _handleEnter() {
                                this._setState('CaptionsSettings.Option.List', [
                                    {
                                        ccSettingItem: CCSettingsItems[this._appearanceListIndex],
                                    },
                                ]);
                            }
                            _scrollList() {
                                setSmooth(this._captionsAppearanceList, 'y', this._appearanceListIndex > 3 ? (this._appearanceListIndex - 3) * -105 : 0, {
                                    duration: 0.3,
                                    delay: 0,
                                });
                            }
                        },
                    ];
                }
            },
            class Option extends CaptionsSettings {
                // @ts-expect-error TS(2416): Property '$enter' in type 'Option' is not assignab... Remove this comment to see the full error message
                $enter({ prevState }, args) {
                    this.ccSettingItem = args.ccSettingItem;
                    this._buildCCSetting();
                    this._captionsSettingHeading.text.text = ClosedCaptionsUtils.getLabel(this.ccSettingItem).toUpperCase();
                    setSmooth(this._captionsAppearanceContainer, 'alpha', 0);
                    setSmooth(this._captionsSettingContainer, 'alpha', 1);
                    this._captionsSettingContainer
                        .animation({
                        duration: 0.3,
                        repeat: 0,
                        stopMethod: 'immediate',
                        actions: [{ p: 'x', v: { 0: 590, 1: 0 } }],
                    })
                        .start();
                }
                $exit() {
                    setSmooth(this._captionsSettingContainer, 'alpha', 0);
                    setSmooth(this._captionsAppearanceContainer, 'alpha', 1);
                    this._captionsAppearanceContainer
                        .animation({
                        duration: 0.3,
                        repeat: 0,
                        stopMethod: 'immediate',
                        actions: [{ p: 'x', v: { 0: -500, 1: 0 } }],
                    })
                        .start();
                }
                _buildCCSetting() {
                    const selectedValue = ClosedCaptionsUtils.getValue(this.ccSettingItem);
                    const items = ClosedCaptionsUtils.getPossibleValuesForKey(this.ccSettingItem);
                    let type;
                    switch (this.ccSettingItem) {
                        case Preferences.CLOSED_CAPTION_FONT_STYLE:
                        case Preferences.CLOSED_CAPTION_FONT_OPACITY:
                        case Preferences.CLOSED_CAPTION_FONT_SIZE:
                        case Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY:
                        case Preferences.CLOSED_CAPTION_WINDOW_OPACITY:
                        case Preferences.CLOSED_CAPTION_EDGE_OPACITY:
                        case Preferences.CLOSED_CAPTION_EDGE_STYLE:
                            type = SelectButton;
                            break;
                        case Preferences.CLOSED_CAPTION_FONT_COLOR:
                        case Preferences.CLOSED_CAPTION_BACKGROUND_COLOR:
                        case Preferences.CLOSED_CAPTION_WINDOW_COLOR:
                        case Preferences.CLOSED_CAPTION_EDGE_COLOR:
                            type = CCColorSelectButton;
                            break;
                    }
                    const children = items === null || items === void 0 ? void 0 : items.map((item, index) => {
                        return {
                            y: index * (60 + 12),
                            w: 500,
                            h: 60,
                            type,
                            radius: 0,
                            fontSize: 30,
                            fontFace: this.ccSettingItem === Preferences.CLOSED_CAPTION_FONT_STYLE
                                ? item.value === 'Default'
                                    ? FONT_FACE.courier
                                    : item.value
                                : FONT_FACE.light,
                            focusFontColor: COLORS.black,
                            unfocusFontColor: COLORS.white2,
                            focusBackGroundColor: COLORS.white,
                            unfocusBackgroundColor: COLORS.transparent,
                            label: Language.translate(item.label),
                            padding: 0,
                            icon: item.value === selectedValue
                                ? 'images/settings/selected.png'
                                : 'images/settings/selection.png',
                            focusSelectedIcon: 'focus_selected.png',
                            selected: item.value === selectedValue,
                            optionValue: item.value,
                        };
                    });
                    if (children)
                        this._captionsSettingList.patch({ children });
                }
                _handleBackButton() {
                    this._setState('CaptionsSettings.Appearance.List');
                    return true;
                }
                static _states() {
                    return [
                        class Back extends Option {
                            $enter() {
                                this._backButton.color = COLORS.white;
                            }
                            $exit() {
                                this._backButton.color = COLORS.mediumGray3;
                            }
                            _handleDown() {
                                this._setState('CaptionsSettings.Option.List');
                            }
                            _handleEnter() {
                                this._setState('CaptionsSettings.Appearance.List');
                            }
                        },
                        class List extends Option {
                            $enter() {
                                this._settingListIndex = 0;
                            }
                            $exit() {
                                this.fireAncestors('$ccSettingUpdated', {
                                    ccSettingItem: this.ccSettingItem,
                                    selectedValue: ClosedCaptionsUtils.getValue(this.ccSettingItem),
                                });
                            }
                            get focusItem() {
                                // This was removed becuase settings would update on focus instead of on enter
                                // But leaving for Peer review in case its needed for otehr devices
                                // this.fireAncestors('$ccSettingUpdated', {
                                //   ccSettingItem: this.ccSettingItem,
                                //   selectedValue: ClosedCaptionsUtils.getPossibleValuesForKey(
                                //     this.ccSettingItem
                                //   )?.[this._settingListIndex]?.value,
                                // })
                                return this.getChildItem(this._settingListIndex);
                            }
                            getChildItem(index = 0) {
                                return this._captionsSettingList.children[index];
                            }
                            _getFocused() {
                                return this.focusItem || this;
                            }
                            _handleUp() {
                                if (this._settingListIndex > 0) {
                                    this._settingListIndex--;
                                }
                                else {
                                    this._setState('CaptionsSettings.Option.Back');
                                }
                            }
                            _handleDown() {
                                if (this._settingListIndex < this._captionsSettingList.children.length - 1) {
                                    this._settingListIndex++;
                                }
                            }
                            $valueChanged(value) {
                                var _a, _b;
                                const focusedValue = (_b = (_a = ClosedCaptionsUtils.getPossibleValuesForKey(this.ccSettingItem)) === null || _a === void 0 ? void 0 : _a[this._settingListIndex]) === null || _b === void 0 ? void 0 : _b.value;
                                const currentValue = ClosedCaptionsUtils.getValue(this.ccSettingItem);
                                if (currentValue !== focusedValue || currentValue !== value) {
                                    ClosedCaptionsUtils.setValue(this.ccSettingItem, focusedValue);
                                    this._captionsSettingList.children.forEach((button) => {
                                        button.selected = button.optionValue === focusedValue;
                                    });
                                    this._updateAppearanceButtonValue(this.ccSettingItem);
                                    this._setState('CaptionsSettings.Appearance.List');
                                }
                            }
                        },
                    ];
                }
            },
        ];
    }
};
